import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  private router = inject(Router);
  private apiUrl = environment.apiUrl.replace('api/', '');

  getMobileAuthUrl(provider: string, code: string, state: string) {
    // Success is a redirect that is handled in the login page.
    const options: HttpOptions = {
      method: 'GET',
      url: `${this.apiUrl}${provider}/callback?code=${code}&state=${state}`,
    };
    CapacitorHttp.get(options)
      .then((response: HttpResponse) => {
        const urlString = response.url.replace('/#/', '/');
        const url = new URL(urlString);
        const params = new URLSearchParams(url.search);
        const accessToken = params.get('access_token');
        const refreshToken = params.get('refresh_token');
        this.router.navigate(['/login'], {
          queryParams: {
            access_token: accessToken,
            refresh_token: refreshToken,
          },
        });
      })
      .catch(error => {
        console.error('callback error', error);
      });
  }
}
