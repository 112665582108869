import { Injectable, NgZone, inject } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import {
  LocalStorageService,
  JWT_REFRESH_TOKEN_LS_KEY,
  JWT_TOKEN_LS_KEY,
} from 'src/services/local-storage.service';
import { UserService } from 'src/services/user.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly zone = inject(NgZone);
  private readonly notificationService = inject(NotificationService);
  private readonly ls = inject(LocalStorageService);
  private readonly dialogService = inject(MatDialog);
  private readonly userService = inject(UserService);

  private refreshingTokenSubject: Subject<void> | null = null;

  private setRefreshingToken = (refreshing: boolean) => {
    if (refreshing) {
      this.refreshingTokenSubject = new Subject<void>();
    } else {
      if (this.refreshingTokenSubject) {
        // Ensure it's a valid Subject
        this.refreshingTokenSubject.next();
        this.refreshingTokenSubject.complete();
      }
      this.refreshingTokenSubject = null;
    }
  };

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let waitingRefresh = false;
        if (error.status === 401) {
          if (req.url.includes('refresh-token')) {
            this.ls.clearAuth();
            this.zone.run(() => {
              this.router.navigate(['/login']);
            });
          }
          const refreshToken = this.ls.getItem(JWT_REFRESH_TOKEN_LS_KEY);
          let tryRefresh = false;
          if (this.refreshingTokenSubject) {
            waitingRefresh = true;
            tryRefresh = false;
          } else if (refreshToken) {
            tryRefresh = true;
          }

          if (tryRefresh) {
            this.setRefreshingToken(true);
            this.ls.clearAuth();
            return this.userService.refreshToken(refreshToken).pipe(
              switchMap(() => {
                const newAccessToken = this.ls.getItem(JWT_TOKEN_LS_KEY);
                this.setRefreshingToken(false);
                req = req.clone({
                  setHeaders: { Authorization: `Bearer ${newAccessToken}` },
                });
                return next.handle(req);
              }),
              catchError(error => {
                this.zone.run(() => {
                  this.ls.clearAuth();
                  this.router.navigate(['/login']);
                });
                return throwError(() => error);
              }),
            );
          } else if (!waitingRefresh) {
            this.zone.run(() => {
              this.ls.clearAuth();
              this.router.navigate(['/login']);
            });
          }
        } else if (error.status === 406) {
          this.zone.run(() => {
            this.ls.clearAuth();
            this.router.navigate(['/login']);
          });
        } else if (error.status === 403) {
          // TODO: refresh current user in store
          this.dialogService.closeAll();
          // this.zone.run(() => {
          //   this.router.navigate(['/unauthorized']);
          // });
        } else if (error.error?.message) {
          this.notificationService.openErrorSnackBar(error.error?.message);
        }
        if (waitingRefresh) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const subject: Subject<HttpEvent<any>> = new Subject<HttpEvent<any>>();
          this.refreshingTokenSubject
            ?.pipe(
              take(1),
              tap(() => {
                const newAccessToken = this.ls.getItem(JWT_TOKEN_LS_KEY);
                req = req.clone({
                  setHeaders: { Authorization: `Bearer ${newAccessToken}` },
                });
                next.handle(req).subscribe(subject);
              }),
            )
            .subscribe();
          return subject.asObservable();
        } else {
          return throwError(() => error);
        }
      }),
    );
  }
}
