import { PermissionsGuard } from 'src/guards/permissions.guard';
import { LocalPermissions } from 'src/models/global-enum';
import { localTemplatesRoutes } from './local-templates.routes';
import { Routes } from '@angular/router';

export const locationsRoutes: Routes = [
  {
    path: 'locations/:locationId',
    children: [
      {
        path: 'documents',
        loadComponent: () =>
          import('../components/local/local-documents/local-documents.component').then(
            c => c.LocalDocumentsComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
        canActivate: [PermissionsGuard],
        title: 'Documents',
      },
      {
        path: 'users',
        loadComponent: () =>
          import('../components/local/local-users/local-users.component').then(
            c => c.LocalUsersComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
        canActivate: [PermissionsGuard],
        title: 'Users',
      },
      {
        path: 'roles',
        loadComponent: () =>
          import('../components/local/local-roles/local-roles.component').then(
            c => c.LocalRolesComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
        canActivate: [PermissionsGuard],
        title: 'Roles',
      },
      ...localTemplatesRoutes,
      {
        path: 'my-tasks',
        loadComponent: () =>
          import('../components/local/local-my-tasks/my-tasks.component').then(
            c => c.MyTasksComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [],
        },
        canActivate: [PermissionsGuard],
        title: 'My Tasks',
      },
      {
        path: 'tasks/closed',
        loadComponent: () =>
          import('../components/local/local-closed-tasks/local-closed-tasks.component').then(
            c => c.LocalClosedTasksComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [
            LocalPermissions.LOCAL_ADMIN,
            LocalPermissions.LOCAL_VIEWER,
            LocalPermissions.ALLOW_TASK_SCORING,
            LocalPermissions.REASSIGN_MY_TASKS_AND_TASK_SETS_TO_OTHERS,
            LocalPermissions.COMPLETE_OTHERS_TASKS,
            LocalPermissions.DELETE_SPAWNED_TASKS,
            LocalPermissions.SPAWN_FROM_EVENT_WIZARD,
            LocalPermissions.ASSIGN_TASK_AND_SETS_TO_OTHERS,
          ],
        },
        canActivate: [PermissionsGuard],
        title: 'Closed Tasks',
      },
      {
        path: 'local-dashboard',
        loadComponent: () =>
          import('../components/local/local-dashboard/local-dashboard.component').then(
            c => c.LocalDashboardComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [],
        },
        canActivate: [PermissionsGuard],
        title: 'Dashboard',
      },
      {
        path: 'event-wizard',
        loadComponent: () =>
          import('../components/local/local-event-wizard/local-event-wizard.component').then(
            c => c.LocalEventWizardComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [],
        },
        canActivate: [PermissionsGuard],
        title: 'Event Wizard',
      },

      {
        path: 'tasks/:taskId',
        loadComponent: () => import('../components/task/task.component').then(c => c.TaskComponent),
        title: 'Local Task',
        canActivate: [PermissionsGuard],
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [],
        },
      },
      {
        path: 'task-sets/:taskSetId',
        loadComponent: () =>
          import('../components/task-set/task-set.component').then(c => c.TaskSetComponent),
        title: 'Local Task Set',
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
      },
      { path: '**', redirectTo: 'local-dashboard' },
    ],
  },
];
