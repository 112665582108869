import { Routes } from '@angular/router';
import { PermissionsGuard } from 'src/guards/permissions.guard';
import { GlobalPermissions } from 'src/models/global-enum';

export const reportsRoutes: Routes = [
  {
    path: 'reports',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../components/reports/reports.component').then(c => c.ReportsComponent),
        title: 'Global Reports',
        data: {
          atLeastOneGlobal: [
            GlobalPermissions.GLOBAL_ADMIN,
            GlobalPermissions.GLOBAL_VIEWER,
            GlobalPermissions.REPORT_VIEWER,
            GlobalPermissions.REPORT_ADMIN,
          ],
          atLeastOneLocal: [],
        },
        pathMatch: 'full',
        canActivate: [PermissionsGuard],
      },
      {
        path: ':reportId',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('../components/report/report.component').then(c => c.ReportComponent),
            title: 'Report Editor',
            data: {
              atLeastOneGlobal: [
                GlobalPermissions.GLOBAL_ADMIN,
                GlobalPermissions.GLOBAL_VIEWER,
                GlobalPermissions.REPORT_VIEWER,
                GlobalPermissions.REPORT_ADMIN,
              ],
              atLeastOneLocal: [],
            },
            canActivate: [PermissionsGuard],
          },
          {
            path: 'view',
            loadComponent: () =>
              import('../components/view-report/view-report.component').then(
                c => c.ViewReportComponent,
              ),
            title: 'Report Viewer',
            data: {
              atLeastOneGlobal: [
                GlobalPermissions.GLOBAL_ADMIN,
                GlobalPermissions.GLOBAL_VIEWER,
                GlobalPermissions.REPORT_VIEWER,
                GlobalPermissions.REPORT_ADMIN,
              ],
              atLeastOneLocal: [],
            },
            canActivate: [PermissionsGuard],
          },
          {
            path: 'sections/:sectionId/rows/:rowId',
            loadComponent: () =>
              import('../components/view-table-report-task/view-table-report-task.component').then(
                c => c.ViewTableReportTaskComponent,
              ),
            title: 'Report Task Viewer',
            data: {
              atLeastOneGlobal: [
                GlobalPermissions.GLOBAL_ADMIN,
                GlobalPermissions.GLOBAL_VIEWER,
                GlobalPermissions.REPORT_VIEWER,
                GlobalPermissions.REPORT_ADMIN,
              ],
              atLeastOneLocal: [],
            },
            canActivate: [PermissionsGuard],
          },
        ],
      },
      { path: '**', redirectTo: '' },
    ],
  },
];
