@if (mobileStore.isMobile()) {
  <ion-app>
    <ion-router-outlet [animated]="false"></ion-router-outlet>
  </ion-app>
} @else {
  <router-outlet></router-outlet>
}
@if (generalStore.loading()) {
  <app-loading loadingText="Loading..."></app-loading>
}
