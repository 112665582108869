import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JWT_TOKEN_LS_KEY, LocalStorageService } from '../services/local-storage.service';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { GeneralStore } from 'src/store/general.store';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private readonly generalStore = inject(GeneralStore);
  private readonly ls = inject(LocalStorageService);
  private apiUrl = environment.apiUrl;
  private unauthenticatedRoutes: string[] = ['customer'];
  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    let addAuthHeader = true;
    this.unauthenticatedRoutes.forEach((route: string) => {
      const testString: string = this.apiUrl + route;
      if (request.url === testString) {
        addAuthHeader = false;
      }
    });
    if (addAuthHeader) {
      const token: string = this.ls.getItem(JWT_TOKEN_LS_KEY);
      if (token && !request.url.includes('.s3.')) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`,
            'custom-domain': this.generalStore.domain(),
          },
        });
      }
    }
    request = request.clone({
      headers: request.headers.set('custom-domain', this.generalStore.domain()),
    });
    return next.handle(request);
  }
}
