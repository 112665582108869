import { Routes } from '@angular/router';
import { GlobalPermissions } from 'src/models/global-enum';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { loginGuard } from '../guards/login.guard';
import { PermissionsGuard } from '../guards/permissions.guard';
import { globalRoutes } from './global.routes';
import { locationsRoutes } from './locations.routes';
import { mobileRoutes } from './mobile.routes';

export const routes: Routes = [
  {
    path: '',
    component: SidebarComponent,
    canActivate: [loginGuard, PermissionsGuard],
    children: [
      ...globalRoutes,
      ...locationsRoutes,
      {
        path: 'locations-and-categories',
        loadComponent: () =>
          import('../components/locations-and-categories/locations-and-categories.component').then(
            c => c.LocationsAndCategoriesComponent,
          ),
        title: 'Locations and Categories',
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [PermissionsGuard],
      },
    ],
  },
  {
    path: '',
    component: SidebarComponent,
    canActivate: [loginGuard, PermissionsGuard],
    children: [...mobileRoutes],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('../components/auth/login/login.component').then(c => c.LoginComponent),
    canActivate: [loginGuard],
    title: 'Login',
  },
  {
    path: 'reset-password/:token',
    loadComponent: () =>
      import('../components/auth/reset-password/reset-password.component').then(
        c => c.ResetPasswordComponent,
      ),
    title: 'Reset Password',
  },
  {
    path: 'unauthorized',
    loadComponent: () =>
      import('../components/unauthorized/unauthorized.component').then(
        c => c.UnauthorizedComponent,
      ),
  },
  {
    path: 'no-permissions',
    loadComponent: () =>
      import('../components/no-permissions/no-permissions.component').then(
        c => c.NoPermissionsComponent,
      ),
  },
  {
    path: 'locations/:locationId/taskboard',
    loadComponent: () =>
      import('../components/taskboard/taskboard.component').then(c => c.TaskboardComponent),
    data: {
      atLeastOneGlobal: [],
      atLeastOneLocal: [],
      checkTaskboardPermissions: true,
    },
    canActivate: [loginGuard, PermissionsGuard],
  },

  {
    path: '**',
    loadComponent: () =>
      import('../components/page-not-found/page-not-found.component').then(
        c => c.PageNotFoundComponent,
      ),
  },
];
