import { Component, effect, inject, isDevMode, NgZone } from '@angular/core';
import Intercom, { update } from '@intercom/messenger-js-sdk';
import LogRocket from 'logrocket';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';
import { LoadingComponent } from './components/loading/loading.component';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/services/user.service';
import { GeneralStore } from 'src/store/general.store';
import { LocationStore } from 'src/store/location.store';
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community';

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);
import { Platform } from '@ionic/angular';
import { MobileStore, MobileType } from './store/mobile.store';
import { SsoService } from './services/sso.service';
import { CustomerResource } from './models/user.model';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Storage } from '@ionic/storage-angular';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { TaskStore } from './store/task.store';
import { TaskSetStore } from './store/task-set.store';

if (environment.shouldEnableIntercom) {
  Intercom({
    app_id: 'gg1xcirb',
  });
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, LoadingComponent, IonApp, IonRouterOutlet],
})
export class AppComponent {
  private readonly router = inject(Router);
  protected readonly generalStore = inject(GeneralStore);
  private readonly taskStore = inject(TaskStore);
  private readonly taskSetStore = inject(TaskSetStore);
  private readonly locationsStore = inject(LocationStore);
  private readonly userService: UserService = inject(UserService);
  private readonly platform: Platform = inject(Platform);
  protected readonly mobileStore = inject(MobileStore);
  private readonly ionicStorage = inject(Storage);
  private readonly zone = inject(NgZone);
  private readonly ssoService = inject(SsoService);

  constructor() {
    this.initializeApp();
    if (!isDevMode()) {
      LogRocket.init('uzclgt/katanaprod', { console: { shouldAggregateConsoleErrors: true } });
    } else {
      LogRocket.init('uzclgt/development-kb3lj', {
        console: { shouldAggregateConsoleErrors: true },
      });
    }

    effect(() => {
      if (environment.shouldEnableIntercom) {
        const tempUser = this.generalStore.userInfo();
        try {
          if (tempUser && tempUser.id) {
            update({
              email: tempUser.userEmail,
              name: tempUser.userFullName,
              created_at: new Date(tempUser.created_at!).getTime(),
              user_id: tempUser.id.toString(),
              user_hash: tempUser.userHash,
            });
          } else {
            update({});
          }
        } catch (e) {
          console.error('Error updating user info in Intercom', e);
        }
      }
    });

    this.userService.getCustomerInfo().subscribe(res => {
      this.generalStore.setCustomerInfo(res);
    });

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
      const url = e.urlAfterRedirects;
      if (url.includes('locations')) {
        const locationId = +url.split('/')[2]!;
        this.locationsStore.setCurrentLocationId(locationId);
      } else if (!url.includes('mobile')) {
        this.locationsStore.clearCurrentLocation();
      }

      // Clear out task or taskSet store if not currently viewing a task in some way (work on task, options, info, etc.).
      // This helps prevent stale data from being shown or potentially updating the incorrect task when navigating to a different task or taskSet.
      if (!url.includes('tasks/')) this.taskStore.clearAllTaskData();
      if (!url.includes('task-sets/')) this.taskSetStore.clearAllTaskSetData();
    });

    this.platform.ready().then(() => {
      let isMobile = false;
      let mobileType: MobileType | undefined = undefined;
      if (this.platform.is('mobileweb')) {
        isMobile = false;
        mobileType = MobileType.MOBILE_WEB;
      } else if (this.platform.is('ios')) {
        isMobile = true;
        mobileType = MobileType.IOS;
      } else if (this.platform.is('android')) {
        isMobile = true;
        mobileType = MobileType.ANDROID;
      } else if (this.platform.is('desktop')) {
        isMobile = false;
        mobileType = undefined;
      } else {
        isMobile = false;
        mobileType = undefined;
      }

      const origin = window.location.origin;
      if (isMobile) {
        this.ionicStorage.create().then(() => {
          this.ionicStorage.get('domain').then((domain: string) => {
            if (domain) {
              this.generalStore.setDomain(domain);
              // We make the customer call on submit.
            }
          });
        });
      } else if (origin.indexOf('localhost') !== -1 || origin.indexOf('127.0.0.1') !== -1) {
        this.generalStore.setDomain('http://localhost:4200/');
        this.userService.getCustomerInfo().subscribe((res: CustomerResource) => {
          this.generalStore.setCustomerInfo(res);
        });
      } else {
        this.generalStore.setDomain(origin);
        this.userService.getCustomerInfo().subscribe((res: CustomerResource) => {
          this.generalStore.setCustomerInfo(res);
        });
      }

      this.mobileStore.setMobile(isMobile);
      this.mobileStore.setMobileType(mobileType!);

      if (isMobile) {
        this.generalStore.setSidebarExpanded(false);
      }
    });
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const urlString = event.url.replace('/#/', '/');
        const url = new URL(urlString);
        const params = new URLSearchParams(url.search);
        if (event.url.includes('callback')) {
          const authCode = params.get('code');
          const state = params.get('state');
          this.ssoService.getMobileAuthUrl('okta', authCode!, state!);
        } else if (event.url.includes('login')) {
          const accessToken = params.get('access_token');
          const refreshToken = params.get('refresh_token');
          this.router.navigate(['/login'], {
            queryParams: {
              access_token: accessToken,
              refresh_token: refreshToken,
            },
          });
        } else {
          this.router.navigate(['/mobile/my-tasks/1']);
        }
      });
    });
  }
}
