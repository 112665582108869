import { PermissionsGuard } from 'src/guards/permissions.guard';
import { GlobalPermissions, LocalPermissions } from 'src/models/global-enum';
import { globalTemplatesRoutes } from './global-templates.routes';
import { reportsRoutes } from './reports.routes';
import { Routes } from '@angular/router';

export const globalRoutes: Routes = [
  {
    path: 'global',
    children: [
      {
        path: 'dashboard',
        loadComponent: () =>
          import('../components/global-dashboard/global-dashboard.component').then(
            c => c.GlobalDashboardComponent,
          ),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN],
        },
        canActivate: [PermissionsGuard],
        title: 'Global Dashboard',
      },
      {
        path: 'no-locations',
        loadComponent: () =>
          import('../components/no-locations/no-locations.component').then(
            c => c.NoLocationsComponent,
          ),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [PermissionsGuard],
        title: 'No Locations',
      },
      {
        path: 'roles',
        loadComponent: () =>
          import('../components/usersAndRoles/roles/roles.component').then(c => c.RolesComponent),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [PermissionsGuard],
        title: 'Global Roles',
      },
      {
        path: 'users-and-roles/roles',
        redirectTo: 'roles',
      },
      {
        path: 'users',
        loadComponent: () =>
          import('../components/usersAndRoles/users/users.component').then(c => c.UsersComponent),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [PermissionsGuard],
        title: 'Global Users',
      },
      {
        path: 'users-and-roles/users',
        redirectTo: 'users',
      },
      {
        path: 'references',
        loadComponent: () =>
          import('../components/global-references/global-references.component').then(
            c => c.GlobalReferencesComponent,
          ),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [PermissionsGuard],
        title: 'Global References',
      },
      ...globalTemplatesRoutes,
      ...reportsRoutes,
      { path: '**', redirectTo: 'dashboard' },
    ],
  },
];
