import { Component, inject, OnDestroy, OnInit, input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingModalDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-loading',
  imports: [],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent implements OnInit, OnDestroy {
  private readonly dialog = inject(MatDialog);

  readonly loadingText = input('Loading...');
  readonly spinnerDiameter = input(50);

  private dialogRef?: MatDialogRef<LoadingModalDialogComponent>;

  ngOnInit(): void {
    this.dialogRef = this.dialog.open(LoadingModalDialogComponent, {
      data: { loadingText: this.loadingText(), spinnerDiameter: this.spinnerDiameter() },
    });
  }

  ngOnDestroy(): void {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }
}
