import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
  Injectable,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  Router,
  RouterStateSnapshot,
  TitleStrategy,
  withHashLocation,
} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { routes } from './routes/app.routes';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { QuillModule } from 'ngx-quill';
import { ErrorInterceptor } from 'src/interceptors/error.interceptor';
import { AuthInterceptorService } from 'src/interceptors/http.interceptor';
import { CredentialsService } from 'src/services/credentials.service';
import { LocationService } from 'src/services/location.service';
import { TaskItemService } from 'src/services/task-item.service';
import { TaskService } from 'src/services/task.service';
import { TaskSetService } from 'src/services/task-set.service';
import { UserService } from 'src/services/user.service';
import { RoleService } from 'src/services/role.service';
import { CategoryService } from './services/category.service';
import { DocumentService } from './services/document.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideIonicAngular } from '@ionic/angular/standalone';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  private readonly title = inject(Title);

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`Terlumina - ${title}`);
    }
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular({ mode: 'ios', animated: false }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withHashLocation()),
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      MatTableModule,
      MatDialogModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      QuillModule.forRoot(),
      IonicModule.forRoot({
        animated: false,
      }),
      IonicStorageModule.forRoot(),
    ),
    UserService,
    LocationService,
    DocumentService,
    CategoryService,
    TaskService,
    TaskSetService,
    TaskItemService,
    RoleService,
    CredentialsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MatPaginatorIntl,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(Sentry.TraceService);
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
  ],
};
