import { PermissionsGuard } from 'src/guards/permissions.guard';
import { Routes } from '@angular/router';

export const mobileRoutes: Routes = [
  {
    path: 'mobile/my-tasks/:type',
    loadComponent: () =>
      import('../components/mobile/mobile-tasks/mobile-tasks.component').then(
        mod => mod.MobileTasksComponent,
      ),
    data: {
      atLeastOneGlobal: [],
      atLeastOneLocal: [],
    },
    canActivate: [PermissionsGuard],
    title: 'My Operational Tasks',
  },
  {
    path: 'mobile/locations/:locationId/my-tasks/:type/tasks/:taskId',
    loadComponent: () =>
      import('../components/mobile/mobile-task/mobile-task.component').then(
        mod => mod.MobileTaskComponent,
      ),
    data: {
      atLeastOneGlobal: [],
      atLeastOneLocal: [],
    },
    canActivate: [PermissionsGuard],
    title: 'My Operational Tasks',
  },
  {
    path: 'mobile/locations/:locationId/closed-tasks/:taskId',
    loadComponent: () =>
      import('../components/mobile/mobile-closed-task/mobile-closed-task.component').then(
        mod => mod.MobileClosedTaskComponent,
      ),
    data: {
      atLeastOneGlobal: [],
      atLeastOneLocal: [],
    },
    canActivate: [PermissionsGuard],
    title: 'My Operational Tasks',
  },
  {
    path: 'mobile/closed-tasks',
    loadComponent: () =>
      import('../components/mobile/mobile-closed-tasks/mobile-closed-tasks.component').then(
        mod => mod.MobileClosedTasksComponent,
      ),
    data: {
      atLeastOneGlobal: [],
      atLeastOneLocal: [],
    },
    canActivate: [PermissionsGuard],
    title: 'Closed Tasks',
  },
  {
    path: 'mobile/account',
    loadComponent: () =>
      import('../components/mobile/mobile-account/mobile-account.component').then(
        mod => mod.MobileAccountComponent,
      ),
    data: {
      atLeastOneGlobal: [],
      atLeastOneLocal: [],
    },
    canActivate: [PermissionsGuard],
    title: 'Account',
  },
];
