import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface LoaderData {
  loadingText: string;
  spinnerDiameter: number;
}

@Component({
  selector: 'app-loading-dialog',
  imports: [MatProgressSpinnerModule],
  templateUrl: './loading-dialog.component.html',
  styleUrl: './loading-dialog.component.scss',
})
export class LoadingModalDialogComponent {
  protected readonly dialogRef = inject(MatDialogRef<LoadingModalDialogComponent>);
  protected readonly data: LoaderData = inject(MAT_DIALOG_DATA);
}
