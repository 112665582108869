import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  JWT_REFRESH_TOKEN_LS_KEY,
  JWT_TOKEN_LS_KEY,
  LocalStorageService,
} from 'src/services/local-storage.service';
import { PermissionsService } from 'src/services/permissions.service';
import { GeneralStore } from 'src/store/general.store';
import { LocationStore } from 'src/store/location.store';

export const PermissionsGuard: CanActivateFn = (route, _state) => {
  const generalStore = inject(GeneralStore);
  const locationsStore = inject(LocationStore);
  const permissionsService = inject(PermissionsService);
  const authService = inject(LocalStorageService);
  const router = inject(Router);
  return new Observable<boolean>(subscriber => {
    const atLeastOneGlobal: number[] = route.data['atLeastOneGlobal'];
    const locationId = Number(route.params['locationId']);
    const atLeastOneLocal: number[] = route.data['atLeastOneLocal'];
    const checkTaskboardPermissions: boolean = route.data['checkTaskboardPermissions'];

    if (_state.url === '/') {
      const token: string = authService.getItem(JWT_TOKEN_LS_KEY);
      const refreshToken: string = authService.getItem(JWT_REFRESH_TOKEN_LS_KEY);
      router.navigate(['/login'], {
        queryParams: { access_token: token, refresh_token: refreshToken },
      });
    } else if (generalStore.isLoaded()) {
      subscriber.next(
        permissionsService.checkRoutePermissions(
          atLeastOneGlobal,
          locationId,
          atLeastOneLocal,
          checkTaskboardPermissions,
        ),
      );
    } else {
      generalStore.getCurrentUser();
      locationsStore.getEntityData();
      generalStore.loadedNotifier?.()?.subscribe(() => {
        subscriber.next(
          permissionsService.checkRoutePermissions(
            atLeastOneGlobal,
            locationId,
            atLeastOneLocal,
            checkTaskboardPermissions,
          ),
        );
      });
    }
  });
};
