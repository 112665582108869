import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  computed,
  effect,
  inject,
  Signal,
  signal,
  untracked,
  WritableSignal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { LocalPermissions } from 'src/models/global-enum';
import { PermissionsService } from 'src/services/permissions.service';
import { HeaderComponent } from '../header/header.component';
import {
  Menu,
  Earth,
  Users,
  MapPinPlus,
  LucideAngularModule,
  ChevronRight,
  ChartBarDecreasing,
  LayoutDashboard,
  Book,
  Map as MapIcon,
  ClipboardCheck,
  PencilRuler,
  FolderClosed,
  CircleUser,
} from 'lucide-angular';
import { NewActionButtonComponent } from '../new-action-button/new-action-button.component';
import { ExpandCollapseComponent } from '../expand-collapse/expand-collapse.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { GeneralStore } from 'src/store/general.store';
import { LucideIconData } from 'node_modules/lucide-angular/icons/types';
import { filter, map } from 'rxjs';
import { LocationService, LocationTaskCount } from 'src/services/location.service';
import { IonTabBar, IonTabButton, IonTabs } from '@ionic/angular/standalone';
import { MobileStore } from 'src/store/mobile.store';

export interface LocationSubmenuItem {
  id: string;
  displayText: string;
  iconName: string;
  routerLink: string;
  hasPermission: boolean;
}

interface MenuItem {
  id: string;
  label: string;
  active: Signal<boolean>;
  icon?: LucideIconData;
  routerLink?: string;
  hide?: Signal<boolean>;
  locationId?: number;
  children?: Signal<MenuItem[]>;
  isExpanded?: WritableSignal<boolean>;
  taskCount?: WritableSignal<LocationTaskCount | undefined>;
}

const GLOBAL_DASHBOARD_ROUTE = '/global/dashboard';
const GLOBAL_REPORTS_ROUTE = '/global/reports';
const GLOBAL_TEMPLATES_ROUTE = '/global/templates';
const GLOBAL_REFERENCES_ROUTE = '/global/references';
const LOCATIONS_AND_CATEGORIES_ROUTE = '/locations-and-categories';
const GLOBAL_USERS_ROUTE = '/global/users';
const GLOBAL_ROLES_ROUTE = '/global/roles';
// Trailing backslash to differentiate from other routes that start with '/locations'.
const LOCATIONS_ROUTE = '/locations/';
// No leading backslash due to parent route including it.
const LOCAL_DASHBOARD_ROUTE = 'local-dashboard';
const MY_TASKS_ROUTE = 'my-tasks';
const CLOSED_TASKS_ROUTE = 'tasks/closed';
const EVENT_WIZARD_ROUTE = 'event-wizard';
const TASK_TEMPLATES_ROUTE = 'templates';
const USERS_ROUTE = 'users';
const ROLES_ROUTE = 'roles';
const DOCUMENTS_ROUTE = 'documents';

const MOBILE_OPERATIONAL_TASKS_ROUTE = 'mobile/my-tasks/1';
const MOBILE_TRAINING_TASKS_ROUTE = 'mobile/my-tasks/2';
const MOBILE_CLOSED_TASKS_ROUTE = 'mobile/closed-tasks';
const MOBILE_ACCOUNT_ROUTE = 'mobile/account';

export const LOCATION_ITEM_IDS = [
  'localDashboard',
  'myTasks',
  'closedTasks',
  'eventWizard',
  'taskTemplates',
  'users',
  'roles',
  'documents',
] as const;

const LOCATION_ITEM_LABELS = {
  [LOCATION_ITEM_IDS[0]]: 'Local Dashboard',
  [LOCATION_ITEM_IDS[1]]: 'My Tasks & Training',
  [LOCATION_ITEM_IDS[2]]: 'Closed Tasks',
  [LOCATION_ITEM_IDS[3]]: 'Event Wizard',
  [LOCATION_ITEM_IDS[4]]: 'Task Templates',
  [LOCATION_ITEM_IDS[5]]: 'Users',
  [LOCATION_ITEM_IDS[6]]: 'Roles',
  [LOCATION_ITEM_IDS[7]]: 'Documents',
} as const;

export const LOCATION_ITEM_ROUTES = {
  [LOCATION_ITEM_IDS[0]]: LOCAL_DASHBOARD_ROUTE,
  [LOCATION_ITEM_IDS[1]]: MY_TASKS_ROUTE,
  [LOCATION_ITEM_IDS[2]]: CLOSED_TASKS_ROUTE,
  [LOCATION_ITEM_IDS[3]]: EVENT_WIZARD_ROUTE,
  [LOCATION_ITEM_IDS[4]]: TASK_TEMPLATES_ROUTE,
  [LOCATION_ITEM_IDS[5]]: USERS_ROUTE,
  [LOCATION_ITEM_IDS[6]]: ROLES_ROUTE,
  [LOCATION_ITEM_IDS[7]]: DOCUMENTS_ROUTE,
} as const;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatToolbarModule,
    RouterModule,
    HeaderComponent,
    NgOptimizedImage,
    LucideAngularModule,
    NewActionButtonComponent,
    ExpandCollapseComponent,
    IonTabButton,
    IonTabBar,
    IonTabs,
    CommonModule,
  ],
})
export class SidebarComponent {
  private readonly router = inject(Router);
  private readonly permissionsService = inject(PermissionsService);
  protected readonly generalStore = inject(GeneralStore);
  protected readonly locationService = inject(LocationService);
  protected readonly mobileStore = inject(MobileStore);
  protected readonly ChevronRight = ChevronRight;
  protected readonly Menu = Menu;
  protected isLocalAdminOnAtLeastOneLocation = computed(() => {
    return this.generalStore
      .assignedLocations()
      .some(location =>
        this.permissionsService.checkRoutePermissions([], location.id, [
          LocalPermissions.LOCAL_ADMIN,
        ]),
      );
  });

  constructor() {
    effect(() => this.updateMenuFromUrl(this.navigationEndUrl()));

    effect(() => {
      const url = this.navigationEndUrl();
      if (!url?.includes(LOCATIONS_ROUTE)) return;
      const locationItem = this.sidebarItems[1];
      // Update the task count on every navigation to a location item.
      untracked(locationItem.children!).forEach(location => {
        if (url.includes(LOCATIONS_ROUTE + location.locationId))
          this.updateLocationTaskCount(location);
      });
    });
  }

  private updateMenuFromUrl = (url?: string) => {
    if (!url) return;
    const locationItem = this.sidebarItems[1];
    // Expand locations and specific location if navigated to.
    if (url.includes(LOCATIONS_ROUTE)) {
      locationItem.isExpanded?.set(true);
      untracked(locationItem.children!).forEach(location => {
        if (url.includes(LOCATIONS_ROUTE + location.locationId)) location.isExpanded?.set(true);
        else location.isExpanded?.set(false);
      });
    } else locationItem.isExpanded?.set(false);
    const globalUsersAndRolesItem = this.sidebarItems[5];
    // Expand global users and roles if navigated to.
    if (url.includes(GLOBAL_USERS_ROUTE) || url.includes(GLOBAL_ROLES_ROUTE)) {
      globalUsersAndRolesItem.isExpanded?.set(true);
    } else globalUsersAndRolesItem.isExpanded?.set(false);
  };

  private updateLocationTaskCount = (location: MenuItem) => {
    this.locationService.getLocationTaskCount(location.locationId!).subscribe(v => {
      location
        .children?.()
        .find(item => item.id === `${LOCATION_ITEM_IDS[1]}-${location.locationId}`)
        ?.taskCount?.set(v);
    });
  };

  private navigationEndUrl = toSignal(
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(v => v?.url),
    ),
  );

  private isGlobalUsersActive = computed(
    () => !!this.navigationEndUrl()?.includes(GLOBAL_USERS_ROUTE),
  );
  private isGlobalRolesActive = computed(
    () => !!this.navigationEndUrl()?.includes(GLOBAL_ROLES_ROUTE),
  );

  private isNotGlobalAdminOrViewer = computed(
    () => !this.generalStore.isGlobalAdmin() && !this.generalStore.isGlobalViewer(),
  );

  protected isMobile = this.mobileStore.isMobile;

  /**
   * A map of location ID + location item ID to a boolean indicating whether the current user has
   * permissions to view the corresponding location item.
   */
  private locationsMenuPermissionsMap = computed(() => {
    const map = new Map<string, boolean>();
    this.generalStore.assignedLocations().map(location => {
      const hasNormalPermissions = this.permissionsService.checkRoutePermissions(
        [],
        location.id,
        [],
      );
      const hasAdminPermissions = this.permissionsService.checkRoutePermissions([], location.id, [
        LocalPermissions.LOCAL_ADMIN,
      ]);

      LOCATION_ITEM_IDS.forEach(itemId => {
        let value = false;
        switch (itemId) {
          case LOCATION_ITEM_IDS[0]:
            value = hasNormalPermissions;
            break;
          case LOCATION_ITEM_IDS[1]:
            value = hasNormalPermissions;
            break;
          case LOCATION_ITEM_IDS[2]:
            value = this.permissionsService.checkRoutePermissions([], location.id, [
              LocalPermissions.LOCAL_ADMIN,
              LocalPermissions.LOCAL_VIEWER,
              LocalPermissions.ALLOW_TASK_SCORING,
              LocalPermissions.REASSIGN_MY_TASKS_AND_TASK_SETS_TO_OTHERS,
              LocalPermissions.COMPLETE_OTHERS_TASKS,
              LocalPermissions.DELETE_SPAWNED_TASKS,
              LocalPermissions.SPAWN_FROM_EVENT_WIZARD,
              LocalPermissions.ASSIGN_TASK_AND_SETS_TO_OTHERS,
            ]);
            break;
          case LOCATION_ITEM_IDS[3]:
            value = this.permissionsService.checkRoutePermissions([], location.id, [
              LocalPermissions.LOCAL_ADMIN,
              LocalPermissions.LOCAL_VIEWER,
              LocalPermissions.SPAWN_FROM_EVENT_WIZARD,
            ]);
            break;
          case LOCATION_ITEM_IDS[4]:
            value = hasAdminPermissions;
            break;
          case LOCATION_ITEM_IDS[5]:
            value = hasAdminPermissions;
            break;
          case LOCATION_ITEM_IDS[6]:
            value = hasAdminPermissions;
            break;
          case LOCATION_ITEM_IDS[7]:
            value = hasAdminPermissions;
            break;
        }
        map.set(location.id + itemId, value);
      });
    });
    return map;
  });

  /**
   * Generated location menu items once they are fetched from the API.
   */
  private locations = computed(() =>
    this.generalStore.assignedLocations().map(location => {
      const routerLinkPrefix = LOCATIONS_ROUTE + location.id + '/';
      return {
        id: 'location-' + location.id,
        locationId: location.id,
        label: location.locationName,
        active: computed(() => !!this.navigationEndUrl()?.includes(routerLinkPrefix)),
        isExpanded: signal(false),
        children: signal(
          LOCATION_ITEM_IDS.map(itemId => ({
            id: `${itemId}-${location.id}`,
            label: LOCATION_ITEM_LABELS[itemId],
            routerLink: `${routerLinkPrefix}${LOCATION_ITEM_ROUTES[itemId]}`,
            active: computed(
              () =>
                !!this.navigationEndUrl()?.includes(
                  `${routerLinkPrefix}${LOCATION_ITEM_ROUTES[itemId]}`,
                ),
            ),
            // Add location task count to My Tasks & Training item.
            ...(itemId === LOCATION_ITEM_IDS[1] && {
              taskCount: signal<LocationTaskCount | undefined>(undefined),
            }),
            hide: computed(() => !this.locationsMenuPermissionsMap().get(location.id + itemId)),
          })),
        ).asReadonly(),
      };
    }),
  );

  /**
   * Sidebar items that are always present.
   */
  protected readonly sidebarItems = [
    {
      id: 'globalDashboard',
      label: 'Global Dashboard',
      icon: Earth,
      routerLink: GLOBAL_DASHBOARD_ROUTE,
      active: computed(
        () =>
          !!this.navigationEndUrl()?.includes(GLOBAL_DASHBOARD_ROUTE) ||
          !!this.navigationEndUrl()?.includes('global/no-locations'),
      ),
      hide: computed(
        () => this.isNotGlobalAdminOrViewer() && !this.isLocalAdminOnAtLeastOneLocation(),
      ),
    } as MenuItem,
    {
      id: 'locations',
      label: 'Locations',
      icon: MapIcon,
      isExpanded: signal(false),
      active: computed(() => !!this.navigationEndUrl()?.includes(LOCATIONS_ROUTE)),
      children: this.locations,
      hide: computed(() => !this.locations().length),
    } as MenuItem,
    {
      id: 'reports',
      label: 'Reports',
      icon: ChartBarDecreasing,
      routerLink: GLOBAL_REPORTS_ROUTE,
      active: computed(() => !!this.navigationEndUrl()?.includes(GLOBAL_REPORTS_ROUTE)),
      hide: computed(
        () =>
          !this.generalStore.isGlobalAdmin() &&
          !this.generalStore.isGlobalViewer() &&
          !this.generalStore.isReportAdmin() &&
          !this.generalStore.isReportViewer(),
      ),
    } as MenuItem,
    {
      id: 'globalTemplates',
      label: 'Global Templates',
      icon: LayoutDashboard,
      routerLink: GLOBAL_TEMPLATES_ROUTE,
      active: computed(() => !!this.navigationEndUrl()?.includes(GLOBAL_TEMPLATES_ROUTE)),
      hide: computed(
        () =>
          this.isNotGlobalAdminOrViewer() && !this.generalStore.isLocalAdminOnAtLeastOneLocation(),
      ),
    } as MenuItem,
    {
      id: 'globalReferences',
      label: 'Global References',
      icon: Book,
      routerLink: GLOBAL_REFERENCES_ROUTE,
      active: computed(() => !!this.navigationEndUrl()?.includes(GLOBAL_REFERENCES_ROUTE)),
      hide: this.isNotGlobalAdminOrViewer,
    } as MenuItem,
    {
      id: 'globalUsersAndRoles',
      label: 'Global Users & Roles',
      icon: Users,
      isExpanded: signal(false),
      active: computed(() => this.isGlobalUsersActive() || this.isGlobalRolesActive()),
      hide: this.isNotGlobalAdminOrViewer,
      children: signal([
        {
          id: 'users',
          label: 'Users',
          routerLink: GLOBAL_USERS_ROUTE,
          active: this.isGlobalUsersActive,
        },
        {
          id: 'roles',
          label: 'Roles',
          routerLink: GLOBAL_ROLES_ROUTE,
          active: this.isGlobalRolesActive,
        },
      ]).asReadonly(),
    } as MenuItem,
    {
      id: 'locationsAndCategories',
      label: 'Locations & Categories',
      icon: MapPinPlus,
      hide: this.isNotGlobalAdminOrViewer,
      routerLink: LOCATIONS_AND_CATEGORIES_ROUTE,
      active: computed(() => !!this.navigationEndUrl()?.includes(LOCATIONS_AND_CATEGORIES_ROUTE)),
    } as MenuItem,
  ] as const;

  protected readonly mobileItems = [
    {
      id: 'mobileOperationalTasks',
      label: 'Tasks',
      icon: ClipboardCheck,
      routerLink: MOBILE_OPERATIONAL_TASKS_ROUTE,
      active: computed(() => !!this.navigationEndUrl()?.includes(MOBILE_OPERATIONAL_TASKS_ROUTE)),
    } as MenuItem,
    {
      id: 'mobileTrainingTasks',
      label: 'Training',
      icon: PencilRuler,
      routerLink: MOBILE_TRAINING_TASKS_ROUTE,
      active: computed(() => !!this.navigationEndUrl()?.includes(MOBILE_TRAINING_TASKS_ROUTE)),
    } as MenuItem,
    {
      id: 'mobileClosedTasks',
      label: 'Closed Tasks',
      icon: FolderClosed,
      routerLink: MOBILE_CLOSED_TASKS_ROUTE,
      active: computed(() => !!this.navigationEndUrl()?.includes(MOBILE_CLOSED_TASKS_ROUTE)),
    } as MenuItem,
    {
      id: 'mobileAccount',
      label: 'Account',
      icon: CircleUser,
      routerLink: MOBILE_ACCOUNT_ROUTE,
      active: computed(() => !!this.navigationEndUrl()?.includes(MOBILE_ACCOUNT_ROUTE)),
    } as MenuItem,
  ];

  protected toggleSidebar = () => {
    this.generalStore.setSidebarExpanded(!this.generalStore.sidebarExpanded());
    if (this.generalStore.sidebarExpanded()) {
      this.updateMenuFromUrl(this.navigationEndUrl());
    } else {
      this.sidebarItems.forEach(item => {
        if (item.isExpanded) item.isExpanded.set(false);
      });
    }
  };

  protected toggleMenuItem = (item: MenuItem) => {
    // If locations or global users and roles are clicked, open the menu, if closed
    if (
      (!this.generalStore.sidebarExpanded() && item.id === 'locations') ||
      item.id === 'globalUsersAndRoles'
    ) {
      this.generalStore.setSidebarExpanded(true);
    }
    item.isExpanded?.set(!item.isExpanded?.());
    if (item.isExpanded?.() && item.id === 'location-' + item.locationId) {
      // Update task count when location is expanded
      this.updateLocationTaskCount(item);
    } else if (!item.isExpanded?.() && item.id === 'locations') {
      // Close all children if locations is collapsed
      item.children?.().forEach(location => {
        location.isExpanded?.set(false);
      });
    }
  };
}
