import { Routes } from '@angular/router';
import { PermissionsGuard } from 'src/guards/permissions.guard';
import { LocalPermissions } from 'src/models/global-enum';

export const localTemplatesRoutes: Routes = [
  {
    path: 'templates',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../components/local/local-templates/local-templates.component').then(
            c => c.LocalTemplatesComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
        pathMatch: 'full',
        canActivate: [PermissionsGuard],
        title: 'Task Templates',
      },
      {
        path: 'tasks/:taskId',
        loadComponent: () => import('../components/task/task.component').then(c => c.TaskComponent),
        title: 'Local Task',
        canActivate: [PermissionsGuard],
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
      },
      {
        path: 'task-sets/:taskSetId',
        loadComponent: () =>
          import('../components/task-set/task-set.component').then(c => c.TaskSetComponent),
        title: 'Local Task Set',
        canActivate: [PermissionsGuard],
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
      },
      { path: '**', redirectTo: '' },
    ],
  },
];
