import { Routes } from '@angular/router';
import { PermissionsGuard } from 'src/guards/permissions.guard';
import { GlobalPermissions, LocalPermissions } from 'src/models/global-enum';

export const globalTemplatesRoutes: Routes = [
  {
    path: 'templates',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../components/global-templates/global-templates.component').then(
            c => c.GlobalTemplatesComponent,
          ),
        title: 'Global Templates',
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN],
        },
        pathMatch: 'full',
        canActivate: [PermissionsGuard],
      },
      {
        path: 'tasks/:taskId',
        loadComponent: () => import('../components/task/task.component').then(c => c.TaskComponent),
        title: 'Global Task',
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN],
        },
        canActivate: [PermissionsGuard],
      },
      {
        path: 'task-sets/:taskSetId',
        loadComponent: () =>
          import('../components/task-set/task-set.component').then(c => c.TaskSetComponent),
        title: 'Global Task Set',
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN],
        },
        canActivate: [PermissionsGuard],
      },
      { path: '**', redirectTo: '' },
    ],
  },
];
