@if (generalStore.userInfo()) {
  @if (isMobile()) {
    <ion-tabs>
      <ion-tab-bar class="mobile-tabs" slot="bottom">
        @for (item of mobileItems; track item.id) {
          <ion-tab-button class="tab" [tab]="item.routerLink" [class.selected]="item.active()">
            <div class="tab-icon-wrapper">
              <lucide-icon
                [img]="item.icon"
                class="tab-icon"
                [class.selected]="item.active()"
              ></lucide-icon>
            </div>
            <div class="tab-text">{{ item.label }}</div>
          </ion-tab-button>
        }
      </ion-tab-bar>
    </ion-tabs>
  } @else {
    <div class="flex flex-nowrap w-full h-full overflow-x-hidden">
      <div class="sidebar-container">
        <div class="side-nav" [class.open]="generalStore.sidebarExpanded()">
          <div class="app-header-logo-container">
            <app-new-action-button
              class="expand-menu-button"
              [icon]="Menu"
              [iconOnlySize]="24"
              themedButton="clear-gray"
              (actionCompleted)="toggleSidebar()"
            ></app-new-action-button>
            <div class="logo">
              <img [ngSrc]="generalStore.logoUrl()!" alt="Logo" height="23" width="157" priority />
            </div>
          </div>
          <mat-nav-list class="nav-list">
            @for (item of sidebarItems; track item.id) {
              @if (!item.hide?.()) {
                <mat-list-item
                  [routerLink]="item.routerLink ?? null"
                  [class.active]="item.active()"
                  [attr.data-testid]="item.id"
                  (click)="toggleMenuItem(item)"
                >
                  <div class="list-item">
                    <lucide-icon [img]="item.icon"></lucide-icon>
                    <span class="full-width">{{ item.label }}</span>

                    @if (item.children?.()?.length) {
                      <lucide-icon
                        [img]="ChevronRight"
                        class="expand-more"
                        [color]="'var(--gray-650)'"
                        [class.rotated]="!!item.isExpanded?.()"
                      ></lucide-icon>
                    }
                  </div>
                </mat-list-item>

                @if (item.children?.()?.length) {
                  <app-expand-collapse [isExpanded]="!!item.isExpanded?.()">
                    <mat-nav-list class="submenu first-level">
                      @for (child of item?.children(); track child.id) {
                        @if (!child.hide?.()) {
                          <mat-list-item
                            [routerLink]="
                              child.routerLink && !child.children?.()?.length
                                ? child.routerLink
                                : null
                            "
                            [class.active]="child.active()"
                            [class.sub-section]="!child.children?.()?.length"
                            [attr.data-testid]="child.id"
                            (click)="toggleMenuItem(child)"
                          >
                            <div class="list-item locations">
                              <span class="full-width ellipsis location-name">
                                {{ child.label }}
                              </span>
                              @if (child.children?.()?.length) {
                                <lucide-icon
                                  [img]="ChevronRight"
                                  [color]="'var(--gray-650)'"
                                  class="expand-more"
                                  [class.rotated]="!!child.isExpanded?.()"
                                ></lucide-icon>
                              }
                            </div>
                          </mat-list-item>
                          <app-expand-collapse [isExpanded]="!!child.isExpanded?.()">
                            <mat-nav-list class="submenu locations-items">
                              @for (secondChild of child?.children?.(); track secondChild.id) {
                                @if (!secondChild.hide?.()) {
                                  <mat-list-item
                                    [routerLink]="
                                      secondChild.routerLink && !secondChild.children?.()?.length
                                        ? secondChild.routerLink
                                        : null
                                    "
                                    [class.active]="secondChild.active()"
                                    [class.sub-section]="!secondChild?.children?.()?.length"
                                    [attr.data-testid]="secondChild.id"
                                  >
                                    <div class="list-item">
                                      <div class="full-width label-with-badge">
                                        {{ secondChild.label }}
                                        @if (secondChild.taskCount) {
                                          <div
                                            class="sidebar-badge"
                                            [class.red]="secondChild.taskCount()?.color === 3"
                                            [class.yellow]="secondChild.taskCount()?.color === 2"
                                            [class.green]="
                                              !secondChild.taskCount() ||
                                              !secondChild.taskCount()?.color ||
                                              secondChild.taskCount()?.color === 1
                                            "
                                          >
                                            <div class="vertical-flex-center badge-count">
                                              {{ secondChild.taskCount()?.count ?? 0 }}
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </mat-list-item>
                                }
                              }
                            </mat-nav-list>
                          </app-expand-collapse>
                        }
                      }
                    </mat-nav-list>
                  </app-expand-collapse>
                }
              }
            }
          </mat-nav-list>
        </div>
      </div>
      <div class="flex-col w-full">
        <app-header></app-header>
        <div class="sidenav-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  }
}
